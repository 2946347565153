export enum ELEMENT_TYPES {
  "IFRAME" = "IFRAME",
  "TEXT" = "TEXT",
  "LINK" = "LINK",
  "SCREENSHARE" = "SCREENSHARE",
  "IMAGE" = "IMAGE",
}


export enum PLAN_TYPE {
  FREE = 'FREE',
  STARTER = 'STARTER',
  PROFESSIONAL = 'PROFESSIONAL',
  UNLIMITED = 'UNLIMITED',
}

export enum SLIDE_TEMPLATES {
  "START_PAGE" = "START_PAGE",
  "TEXT_IMAGE" = "TEXT_IMAGE",
  "IMAGE_2TEXT" = "IMAGE_2TEXT",
  "PLAIN_TEXT" = "PLAIN_TEXT",
}

export enum OBJECT_FIT {
  "CONTAIN" = "CONTAIN",
  "COVER" = "COVER",
}

export enum TEXT_ALIGN {
  "START" = "START",
  "CENTER" = "CENTER",
  "END" = "END",
}

export enum ROLE {
  "ADMIN" = "ADMIN",
  "COLLABORATOR" = "COLLABORATOR",
  "GUEST" = "GUEST",
}

export enum CONNECTION_STATUS {
  "NORMAL" = "NORMAL",
  "ERROR" = "ERROR",
  "RECONNECTING" = "RECONNECTING",
}

export interface Image {
  cdnUrl: string;
  height: string | number;
  width: string | number;
}

export enum PLOT_MODE {
  "VIEW_ALL" = "VIEW_ALL",
  "NO_ACCESS" = "NO_ACCESS",
}

export enum PLOT_THEME {
  "BLACK" = "BLACK",
  "LIGHT" = "LIGHT",
  "BLUE" = "BLUE",
  "YELLOW" = "YELLOW",
}

export interface Pos {
  x: number;
  y: number;
}

export interface Size {
  w: number;
  h: number;
}

export interface ElementBase {
  id: string;
  type: ELEMENT_TYPES;
  pos: Pos;
  size: Size;
}

export type Position = {
  x: number;
  y: number;
};

export interface TextElementData {
  content: string;
  align: TEXT_ALIGN;
  timestamp: number;
}

export interface TextElement extends ElementBase {
  data: TextElementData;
}

export interface ImageElementData {
  cdnUrl: string;
  objectFit: OBJECT_FIT;
  background: string;
  height: string | number;
  width: string | number;
}

export interface ImageElement extends ElementBase {
  data: ImageElementData;
}

export interface IframeElementData {
  url: string;
}

export interface IframeElement extends ElementBase {
  data: IframeElementData;
}

export interface ParticipantCursor {
  pos: Pos;
  identityId: string;
}

export type RoomElement = IframeElement | ImageElement | TextElement;

export interface Room {
  roomId: string;
  component: string;
  name: string;
  capacity: number;
  elements: RoomElement[];
}

export interface ElementUpdate {
  id: string;
  pos?: Pos;
  size?: Size;
  data?: ImageElementData | TextElementData | IframeElementData;
}

export const getLoraOptons = (id: string | undefined) => {
  const tiguanLoraUsers = (
    id === 'user_gbiIir1nLvbrfkiNI_pYo'
    || id === 'user_jKO4RFY8WyAV72jLWFg7g'
    || id === 'user_jq3MVJrq1AsUU_nDD4Mas'
    || id === 'user_vKpNcEB-RDH-QZH6bArzv'
  )
  const volvoLoraUsers = (
    id === 'user_k_McJZ0_LViQXlqY4S0pi'
  )

  const rrUsers = (
    id === 'user_0i70SP3kEydAww20vpiuc'
  )
  const secretModeEnabled = id === 'user_ncW1SxIG5X3mwQGtmPp5Z' || id === 'user_ztvePOlS8Ylq0N0c5wFhK' || id === 'user_RzgcIMD1nu3VgXxx1ohCx'
  if (secretModeEnabled) {
    return [
      { value: '', label: 'None' },
      { value: 'mag_eqs', label: 'Mercedes EQS (Demo car)' },
      { value: 'mag_bmw', label: 'Mag Bmw X3M' },
      { value: 'u_tiguan', label: 'VW Tiguan (Demo car)' },
      { value: 'u_bmw', label: 'BMW M5' },
      { value: 'mag_volvo', label: 'Volvo' },
      { value: 'r_rr', label: 'Range Rover' },
      { value: 'm_s5', label: 'Audi S5' },
    ]
  }

  if (tiguanLoraUsers) {
    return [
      { value: '', label: 'None' },
      { value: 'u_tiguan', label: 'VW Tiguan (Demo car)' },
    ]
  } 

  if (volvoLoraUsers) {
    return [
      { value: '', label: 'None' },
      { value: 'u_tiguan', label: 'VW Tiguan (Demo car)' },
      { value: 'mag_volvo', label: 'Volvo' },
    ]
  }

  if (rrUsers) {
    return [
      { value: '', label: 'None' },
      { value: 'u_tiguan', label: 'VW Tiguan (Demo car)' },
      { value: 'r_rr', label: 'Range Rover' },
    ]
  }

  return [
    { value: '', label: 'None' },
    { value: 'u_tiguan', label: 'VW Tiguan (Demo car)' },
  ]
}