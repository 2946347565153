import {
    Paper,
    Title,
    Text,
    Container,
} from "@mantine/core";

import styles from "./paymentRedirect.module.scss";
import Logo from "../../../components/Logo";
import { useEffect } from "react";

const SubscriptionPaymentRedirectScreen = () => {

    useEffect(() => {
        window.localStorage.setItem("sync", Date.now().toString());
    }, []);
    return (
        <div className={styles.container}>
            <Container size={620} my={40}>
                <div className={styles.logo}>
                    <Logo size={120} />
                </div>
                <Paper withBorder shadow="md" p={30} mt={30} radius="md" style={{ background: '#ffffff' }}>
                    <Title
                        
                        align="center"
                        sx={(theme) => ({
                            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
                            fontWeight: 900,
                        })}
                    >
                        Thank you! Subscription has been applied to your account.
                    </Title>
                    <Text  size="sm" align="center" mt={25}>
                        You can close this tab now.
                    </Text>
                </Paper>
            </Container>
        </div>
    );
};

export default SubscriptionPaymentRedirectScreen;
