import { Canvas } from '@react-three/fiber'
import { Center, ContactShadows, Environment, OrbitControls } from '@react-three/drei'
import { Model } from './Datsun'
import { forwardRef, Suspense, useState } from 'react'
import { Accordion, Button, Flex, Progress, Slider, Textarea, Text, rem, Select } from '@mantine/core'
import ImageObserved from '../pipeline/Image'
import { priceMap } from '../pipeline/Main'
import { PipelineParameters } from '../pipeline/Pipeline'
import { NOTIFICATION_SERVICE } from '../../../components/notifications/NotificationService'

// {
//   images: { url: string, preview: string }[];
//   previewHdrl: string;
//   enhancedHdrlFile: string;
//   modelVersion?: string;
//   loraName?: string;
// }[]
export default function ControlnetScreen(props: any) {

  const {
    latestCustomModelsRun,
    modelParams,
    setModelParams,
    runAsyncConvertBase64,
    runCreatePipeline,
    setBillingModalOpen,
    setLatestCustomModelsRun,
    setPipelines,
    gallery,
    carLoraOptions,
  } = props


  const [controlnet, setControlnet] = useState(0.5)
  const [loraName, setLoraName] = useState('')
  const [carLoraName, setCarLoraName] = useState('')
  const generateButtton = (model: { credits: number, text?: string, intercom?: string }) => {
    return <Flex
      gap={12}
      align={'center'}
      data-intercom-target={
        model.intercom
      }>
      <Text>
        {typeof model.text === 'string' ? model.text : 'Generate'}
      </Text>
      <Flex align={'center'} style={{
        borderRadius: '12px', background: '#f85e39',
        padding: '0 8px'
      }}>

        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.98014 1.33337C4.30014 1.33337 1.31348 4.32004 1.31348 8.00004C1.31348 11.68 4.30014 14.6667 7.98014 14.6667C11.6601 14.6667 14.6468 11.68 14.6468 8.00004C14.6468 4.32004 11.6668 1.33337 7.98014 1.33337ZM10.4801 8.23337L8.00014 11.0534L7.70681 11.3867C7.30014 11.8467 6.96681 11.7267 6.96681 11.1067V8.46671H5.83348C5.32014 8.46671 5.18014 8.15337 5.52014 7.76671L8.00014 4.94671L8.29348 4.61337C8.70014 4.15337 9.03348 4.27337 9.03348 4.89337V7.53337H10.1668C10.6801 7.53337 10.8201 7.84671 10.4801 8.23337Z" fill="white" />
        </svg>
        <Text >{model.credits}</Text>
      </Flex >
    </Flex>
  }

  console.log('gallery', gallery)

  const SelectItem = forwardRef<HTMLDivElement, any>(
    (option: any, ref) => (
      <div ref={ref} {...option}>
        <Flex ref={ref} align="center">
          <Flex h={80} w={80}>
            <ImageObserved
              width={80}
              height={80}
              dark
              src={option.preview}
            />
          </Flex>
          <Text ml={10}>{option.label}</Text>
        </Flex>
      </div>
    )
  );
  return (
    <Flex w={'100%'} mt={12} pt={16} pb={16} pl={20} pr={20} style={{ borderRadius: '4px' }} gap={32}>

      <Flex direction={'column'} miw={'300px'} w={'300px'} gap={12}>
        <Text fz={20}>Test model</Text>

        <Textarea
          data-intercom-target={
            'promptInfo'
          }
          w="100%"
          minRows={7}
          size="sm"
          label="Prompt"
          value={modelParams.prompt}
          description=""
          placeholder="Street view"
          onChange={(e) => {
            setModelParams({
              ...modelParams,
              prompt: e.currentTarget.value
            })
          }}
        />
        <Select
          w="300px"
          size="sm"
          description=""
          label="Select location lora"
          placeholder="Pick value"
          value={loraName}
          onChange={(value) => {
            console.log(value)
            setLoraName(value!)
          }}


          itemComponent={SelectItem}
          mb={10}
          data={
            gallery.filter(itm => itm.loraPath).map(itm => (
              { value: itm.loraPath, label: itm.loraPath.replace('.safetensors', ''), preview: itm.images[0].preview }
            ))
          }
        />
        <Select
          w="300px"
          size="sm"
          description=""
          label="Select car lora"
          placeholder="Pick value"
          value={carLoraName}
          onChange={(value) => {
            setCarLoraName(value!)
          }}
          mb={10}
          data={
            carLoraOptions
          }
        />
        <Accordion chevronPosition="left" defaultValue="Apples">
          <Accordion.Item key={'key'} value={'value'}>
            <Accordion.Control>Advanced settings</Accordion.Control>
            <Accordion.Panel>
              <Flex direction={'column'} gap={12}>
                <Text
                  size="xs"
                  fz={14}
                  mt={12}
                >
                  Controlnet streight (0.5 is default)
                </Text>
                <Slider
                  size="xs"
                  color="#f53c02"
                  sx={{ maxWidth: 360 }}
                  mt={0}
                  min={0}
                  max={2}
                  step={0.01}
                  label={controlnet}
                  value={+controlnet}
                  onChangeEnd={(e) => {
                    setControlnet((prevState) => (+e.toFixed(2)))
                  }}
                />
              </Flex>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>

        <Flex gap={12} mt={32} justify={'end'}>
          <Button
            styles={{
              root: {
                color: 'rgba(230, 230, 230, 1)',
                height: rem(36),

                fontSize: '14px',
                background: '#f53c02 !important',
                border: 'none',
                position: 'relative',
              }
            }}
            disabled={modelParams.prompt.length === 0}
            variant="default"
            onClick={async () => {

              const canvasParent = document.getElementById('controlnetpano') as HTMLCanvasElement
              const canvas = canvasParent.querySelector('canvas') as HTMLCanvasElement
              const image = canvas.toDataURL('png')
              const { url } = await runAsyncConvertBase64({ url: image })


              const pipeline: PipelineParameters = {
                provider: 'comfy',
                type: 'controlV1',
                loraName: loraName,
                carLoraName: carLoraName,
                streight: controlnet.toString(),
                credits: 0,
                inputSrc: url,
                prompt: modelParams.prompt,
                negativePrompt: '',

                srcset: [],
              }
              const res = await runCreatePipeline(pipeline);

              if (res.error) {
                NOTIFICATION_SERVICE.errorNotification(
                  "Not enough credits"
                );
                setBillingModalOpen(true)
              } else {
                setPipelines((pps: any) => [{
                  ...res.data,
                  status: res.status
                }, ...pps])

                setLatestCustomModelsRun([
                  {
                    srcset: [],
                    jobId: res.data.jobId
                  }
                ])
              }

              // if (modelParams.secondLoraName) {
              //   const prompt = modelParams.secondLoraName ? modelParams.prompt : (modelParams.prompt + `, ${selectedModel?.data.description || 'Photograph'} in style of MAGAI`)

              //   setLatestCustomModelsRun([])
              //   for (const perspective of modelParams.carPerspective) {
              //     const pipeline: PipelineParameters = {
              //       provider: 'replicate',
              //       type: 'customModelV1',
              //       modelName: selectedModel!.data.name,
              //       modelVersion: selectedModel!.version,
              //       prompt: prompt,
              //       aspectRation: modelParams.aspect_ratio,
              //       loraScale: modelParams.lora_scale,
              //       secondLoraScale: modelParams.secondLoraScale,
              //       secondLoraName: modelParams.secondLoraName,
              //       credits: priceMap.CUSTOM_MODEL_GEN,
              //       replicateVersion: selectedModel!.version,
              //       steps: modelParams.steps,
              //       cfg: modelParams.cfg,
              //       srcset: [],
              //       color: modelParams.color,
              //       carPerspective: perspective,
              //       motionBlur: modelParams.motionBlur,
              //     }
              //     const res = await runCreatePipeline(pipeline);

              //     if (res.error) {
              //       NOTIFICATION_SERVICE.errorNotification(
              //         "Not enough credits"
              //       );
              //       setBillingModalOpen(true)
              //     } else {
              //       setPipelines(ppp => [{
              //         ...res.data,
              //         status: res.status
              //       }, ...ppp])

              //       setLatestCustomModelsRun(runs => [
              //         ...runs,
              //         {
              //           srcset: [],
              //           jobId: res.data.jobId
              //         }
              //       ])
              //     }
              //   }
              // } else {

              //   const prompt = (modelParams.prompt + `, ${selectedModel?.data.description || 'Photograph'} in style of MAGAI`)

              // const pipeline: PipelineParameters = {
              //   provider: 'replicate',
              //   type: 'customModelV1',
              //   modelName: selectedModel!.data.name,
              //   modelVersion: selectedModel!.version,
              //   prompt: prompt,
              //   aspectRation: modelParams.aspect_ratio,
              //   loraScale: modelParams.lora_scale,
              //   secondLoraScale: modelParams.secondLoraScale,
              //   secondLoraName: modelParams.secondLoraName,
              //   credits: priceMap.CUSTOM_MODEL_GEN,
              //   replicateVersion: selectedModel!.version,
              //   steps: modelParams.steps,
              //   cfg: modelParams.cfg,
              //   srcset: [],
              //   color: modelParams.color,
              //   carPerspective: '',
              //   motionBlur: modelParams.motionBlur,
              // }
              // const res = await runCreatePipeline(pipeline);

              // if (res.error) {
              //   NOTIFICATION_SERVICE.errorNotification(
              //     "Not enough credits"
              //   );
              //   setBillingModalOpen(true)
              // } else {
              //   setPipelines([{
              //     ...res.data,
              //     status: res.status
              //   }, ...pipelines])

              //   setLatestCustomModelsRun([
              //     {
              //       srcset: [],
              //       jobId: res.data.jobId
              //     }
              //   ])
              // }
              // }
            }}
          >
            {generateButtton({ credits: modelParams.secondLoraName ? modelParams.carPerspective.length * priceMap.CUSTOM_MODEL_GEN : priceMap.CUSTOM_MODEL_GEN, intercom: 'customModelGenerateButton' })}
          </Button>
        </Flex>
      </Flex>

      {
        latestCustomModelsRun.length > 0 ? (
          <Flex
            w={'70%'}
            gap={20}
            direction={"column"}
          >
            {
              latestCustomModelsRun.map((run, index) => {
                if (run.srcset.length > 0) {
                  return (
                    <Flex
                      gap={20}
                    >
                      {run.srcset.map((src, index) => (
                        <Flex direction={'column'}>

                          <ImageObserved
                            width={'440px'}
                            height={'440px'}
                            dark={false}
                            src={src}
                          />
                        </Flex>
                      ))}
                    </Flex>
                  )
                } else {
                  return (
                    <Flex w="100%" h="440px" p={32} align={'center'} justify={'center'} direction={'column'}>
                      <Text color="#f53c02" fz={12}>Generation in progress.</Text>
                      <Progress radius="lg" size="lg" mt={30} color="#f53c02" value={100} striped animate={true} w={'200px'} />
                    </Flex>
                  )
                }
              })
            }
          </Flex>
        )
          :
          <Flex
            style={{ position: 'relative' }}
          >
            <Flex
              w={'1024px'}
              h={'1024px'}
              style={{ border: '3px solid black', position: 'absolute', left: '0', top: '0' }}
            >
              <Canvas gl={{ antialias: false, preserveDrawingBuffer: true }} id="controlnetpano" shadows camera={{ position: [4, 0, 6], fov: 35 }}>
                <group position={[0, -0.75, 0]}>
                  <Center>
                    <Model color={'#ff9621'} />
                  </Center>

                </group>
                <ContactShadows resolution={1024} frames={1} position={[0, -0.65, 0]} scale={15} blur={1.5} opacity={1} far={20} />
                <OrbitControls makeDefault minPolarAngle={0} maxPolarAngle={Math.PI / 2} />
                {/* <directionalLight position={[3.3, 1.0, 4.4]} intensity={4} /> */}
                {/* <directionalLight position={[-3.3, -1.0, -4.4]} intensity={4} /> */}
                <color attach="background" args={['#dfdfdf']} />
                <Suspense fallback={null}>
                  <Environment
                    files={'/kiara_1_dawn_1k.hdr'}
                    background={false}
                  />
                </Suspense>
              </Canvas>
            </Flex>
          </Flex>

      }
    </Flex>
  )
}
